import { ConsultantProfileFragmentFragment } from "@/gql"
import store from "@/store"
import { apolloProvider } from "@/main"

interface useProfileValue {
  profile: ConsultantProfileFragmentFragment | undefined
}
const useProfile = (): useProfileValue => {
  store.dispatch("profile/requireProfile", { provider: apolloProvider })

  return {
    profile: store.state.profile?.profile,
  }
}

export { useProfile }
