
import { Vue, Prop, Component } from "vue-property-decorator"

@Component
export default class Banner extends Vue {
  @Prop()
  readonly heading!: string

  @Prop()
  readonly color!: string

  @Prop()
  readonly actionTo!: { [key: string]: any }

  @Prop()
  readonly actionText!: string

  show = true
}
